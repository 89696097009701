import { generateOtpForPhoneVerification } from "@api/authentications";
import Button from "@components/Button";
import Image from "@components/_shared/NextImage/v2";
import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useTranslations } from "@context/translations";
import {
  getPhoneVerificationFormNextClickEvent,
  getPhoneVerificationRetryClickEvent,
  getPhoneVerificationValidateOTPSuccessEvent,
} from "@helpers/genzo/genzoEventActionsBuilder";
import { VariantButton } from "@shopcashTypes/variantButton";
import clsx from "clsx";
import { FC, useState } from "react";
import OtpForm from "./OTPForm";
import PhoneForm from "./PhoneForm";
import styles from "./PhoneVerification.module.scss";

export enum PhoneVerificationState {
  Introduction = "introduction",
  Form = "form",
  Otp = "otp",
  Success = "success",
  Failure = "failure",
}

interface PhoneVerificationProps {
  accessToken: string;
  continueCTALabel?: string;
  onContinue: (verificationState: boolean) => void;
  onIntroNextClick?: () => void;
}

const PhoneVerification: FC<PhoneVerificationProps> = ({
  accessToken,
  onContinue,
  continueCTALabel,
  onIntroNextClick,
}) => {
  const [screenState, setScreenState] = useState<PhoneVerificationState>(
    PhoneVerificationState.Introduction
  );
  const [countryCode, setCountryCode] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const { isRtl, locale } = useSiteConfig();
  const [otpRequestId, setOtpRequestId] = useState<string>("");
  const [failureMessage, setFailureMessage] = useState<string>("");
  const translate = useTranslations();
  const { trackEvent } = useGenzoTracking();

  const handleIntroductionNextClick = () => {
    onIntroNextClick && onIntroNextClick();
    setScreenState(PhoneVerificationState.Form);
  };

  const handlePhoneNumberSubmit = (
    countryCode: string,
    phoneNumber: string,
    captchaToken: string
  ) => {
    trackEvent(getPhoneVerificationFormNextClickEvent());
    setCountryCode(countryCode);
    setPhoneNumber(phoneNumber);

    // Call Generate OTP
    generateOtpForPhoneVerification(
      countryCode,
      phoneNumber,
      captchaToken,
      accessToken,
      locale
    )
      .then((r: Response) => {
        if (r.ok) {
          return r.json().then((body: OtpResponse) => {
            if (body.otpRequest?.id) {
              setOtpRequestId(body.otpRequest.id);
              setScreenState(PhoneVerificationState.Otp);
            }
          });
        }
      })
      .catch(() => {
        // log to sentry
        // Handle Errors here.
      });
  };

  const handleOTPVerificationSuccess = () => {
    const genzoAction = continueCTALabel
      ? "continue_to_store"
      : "browse_stores";
    trackEvent(getPhoneVerificationValidateOTPSuccessEvent(genzoAction));
    setScreenState(PhoneVerificationState.Success);
  };

  const handleOTPVerificationFailure = (type: "duplicate" | "generic") => {
    if (type === "duplicate") {
      setFailureMessage(translate("phone_number_exists"));
    } else {
      setFailureMessage(
        `${translate("net_error_title")}. ${translate("net_error_generic")}`
      );
    }
    setScreenState(PhoneVerificationState.Failure);
  };

  const handlePhoneVerificationRetryClick = (): void => {
    trackEvent(getPhoneVerificationRetryClickEvent());
    setScreenState(PhoneVerificationState.Form);
  };

  return (
    <div className={clsx(styles.container, isRtl && styles.rtl)}>
      {(screenState === PhoneVerificationState.Introduction ||
        screenState === PhoneVerificationState.Form) && (
        <Introduction
          onNextClick={
            screenState === PhoneVerificationState.Introduction
              ? handleIntroductionNextClick
              : null
          }
        />
      )}

      {screenState === PhoneVerificationState.Form && (
        <PhoneForm onNextClick={handlePhoneNumberSubmit} />
      )}

      {screenState === PhoneVerificationState.Otp && (
        <OtpForm
          countryCode={countryCode}
          phoneNumber={phoneNumber}
          accessToken={accessToken}
          otpRequestId={otpRequestId}
          onResendOtpClick={handlePhoneNumberSubmit}
          onSuccess={handleOTPVerificationSuccess}
          onFailure={handleOTPVerificationFailure}
        />
      )}

      {screenState === PhoneVerificationState.Success && (
        <>
          <Image
            src="https://zen.shopcash.com/cdn-cgi/image/width=320/assets/static/illustrations/tick.png"
            alt=""
            width={160}
            height={160}
          />
          <div className={styles.header}>
            {translate("updated_phone_number_header")}🎉
          </div>
          <div>{translate("update_phone_number_success_desc")}</div>

          <Button
            onClick={() => onContinue(true)}
            variant={VariantButton.Primary}
            fullWidth
            large
          >
            {continueCTALabel
              ? continueCTALabel
              : translate("phone_number_success_CTA")}
          </Button>
        </>
      )}

      {screenState === PhoneVerificationState.Failure && (
        <>
          <Image
            src="https://zen.shopcash.com/cdn-cgi/image/width=320/assets/static/illustrations/exclamation.png"
            alt=""
            width={160}
            height={160}
          />
          <div className={styles.header}>
            {translate("add_phone_number_header")}
          </div>
          <div>{failureMessage}</div>

          <Button
            onClick={handlePhoneVerificationRetryClick}
            variant={VariantButton.Primary}
            fullWidth
            large
          >
            {translate("phone_number_exists_CTA")}
          </Button>
        </>
      )}
    </div>
  );
};

interface IntroductionProps {
  onNextClick?: () => void;
}

const Introduction: FC<IntroductionProps> = ({ onNextClick }) => {
  const translate = useTranslations();
  return (
    <>
      <div>
        <Image
          src="https://zen.shopcash.com/cdn-cgi/image/width=320/assets/static/illustrations/phone-verification.png"
          alt=""
          width={160}
          height={160}
        />
      </div>
      <div className={styles.header}>{translate("phone_number_update")}</div>
      <div>{translate("update_phone_number_bottomsheet")}</div>
      {onNextClick && (
        <Button
          onClick={onNextClick}
          variant={VariantButton.Primary}
          fullWidth
          large
        >
          {translate("next")}
        </Button>
      )}
    </>
  );
};

export default PhoneVerification;
