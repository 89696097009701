import { updateProfilePhoneNumber } from "@api/account";
import { parseResponseBody } from "@api/apiHelpers";
import { verifyOtp } from "@api/authentications";
import Button from "@components/Button";
import OtpInput from "@components/OtpInput";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useTranslations } from "@context/translations";
import { getPhoneVerificationValidateOTPClickEvent } from "@helpers/genzo/genzoEventActionsBuilder";
import useCountDownTimer from "@helpers/useCountDownTimer";
import { VariantButton } from "@shopcashTypes/variantButton";
import { useCallback, useEffect, useRef, useState } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import styles from "./OTPForm.module.scss";

interface OtpFormProps {
  countryCode: string;
  phoneNumber: string;
  onResendOtpClick: (
    countryCode: string,
    phoneNumber: string,
    captchaToken: string
  ) => void;
  onSuccess: () => void;
  onFailure: (type: "duplicate" | "generic") => void;
  otpRequestId: string;
  accessToken: string;
}

const OtpForm: React.FC<OtpFormProps> = ({
  countryCode,
  phoneNumber,
  onResendOtpClick,
  onSuccess,
  onFailure,
  otpRequestId,
  accessToken,
}) => {
  const translate = useTranslations();
  const [otpValue, setOtpValue] = useState<string>();
  const [otpMessage, setOtpMessage] = useState<string>("");
  const [hasOtpError, setHasOtpError] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState<boolean>(false);
  const [displayTimer, timer, setTimer] = useCountDownTimer(60);
  const { trackEvent } = useGenzoTracking();

  const otpInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    otpInputRef.current.focus();
  }, []);

  const handleOnOtpInputChange = (value: string): void => {
    setOtpValue(value);
    setIsDisabled(value.length !== 6);
  };

  const handleOnVerifyOtpClick = (
    ev: React.FormEvent<HTMLFormElement>
  ): void => {
    ev.preventDefault();
    setIsLoading(true);

    const event = getPhoneVerificationValidateOTPClickEvent();
    trackEvent(event);

    // Call Verify OTP
    verifyOtp(otpValue, otpRequestId, accessToken)
      .then((r) => {
        setIsLoading(false);
        if (r.ok) {
          updateProfilePhoneNumber(accessToken, otpRequestId).then(
            (response) => {
              if (!response) {
                onFailure("generic");
              } else if (parseResponseBody(response)) {
                onSuccess();
              } else if (
                response["errors"]?.phoneNumber.includes(
                  "has already been taken"
                )
              ) {
                onFailure("duplicate");
              } else {
                onFailure("generic");
              }
            }
          );
        } else {
          return r.json().then(() => {
            setOtpMessage(translate("invalid_otp"));
            setHasOtpError(true);
          });
        }
      })
      .catch(() => {
        // log to sentry
        // Handle Errors here.
        setIsLoading(false);
      });
  };

  const handleOnResendOtpClick = async (
    countryCode: string,
    phoneNumber: string
  ): Promise<void> => {
    // Call generate OTP for resend OTP
    onResendOtpClick(countryCode, phoneNumber, token);
    setRefreshReCaptcha(!refreshReCaptcha);

    setTimer(60);
  };

  const handleOnVerify = useCallback((token: string) => {
    setToken(token);
  }, []);

  return (
    <>
      <div className={styles.title}>
        {translate("verify_phone_number_header")}
      </div>
      <div className={styles.note}>
        {translate("otp_instruction", [""])}
        <div
          className={styles.phoneNumber}
        >{`${countryCode} ${phoneNumber}`}</div>
      </div>
      <form onSubmit={handleOnVerifyOtpClick}>
        <div className={styles.OtpInput}>
          <OtpInput
            ref={otpInputRef}
            id="otp"
            name="otp"
            message={otpMessage}
            hasError={hasOtpError}
            onChange={handleOnOtpInputChange}
          />
        </div>

        <div className={styles.buttonContainer}>
          <Button
            variant={VariantButton.Primary}
            large
            fullWidth
            disabled={isDisabled}
            loading={isLoading}
          >
            {translate("verify_phone_number_header")}
          </Button>
        </div>
      </form>

      {timer !== 0 ? (
        <div className={styles.otpTimer}>
          {translate("mobile_otp_sent", [displayTimer])}
        </div>
      ) : (
        <>
          <div
            className={styles.resendOtp}
            onClick={() => handleOnResendOtpClick(countryCode, phoneNumber)}
          >
            {translate("lbl_did_not_rcv_otp")}{" "}
            <span>{translate("resend_otp")}</span>
          </div>
        </>
      )}

      <GoogleReCaptcha
        onVerify={handleOnVerify}
        refreshReCaptcha={refreshReCaptcha}
      />
    </>
  );
};

export default OtpForm;
